<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text con imagen de fondo -->
      <b-col md="6" lg="8" class="p-4 bg-img order-1 order-md-0 d-none d-md-block">
        <div class="d-flex  align-items-center justify-content-center position-relative">
          <!-- Contenedor de botones sobre la imagen -->
          <div class="text-center" style="z-index: 2">
            <h2 class="text-white font-weight-bolder mb-0 display-4">REGÍSTRATE GRATIS</h2>
            <p class="text-white mb-4 font-weight-bolder">SELECCIONA TU TIPO DE USUARIO</p>
            <div class="d-flex justify-content-center">
              <b-embed type="video" aspect="16by9" controls class="video-element">
                <source src="@/assets/compitienda_V1.mp4" type="video/mp4">
              </b-embed>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <!-- Botón Negocio -->
              <b-button
                variant="register-button"
                class="btn-lg mx-2 register-button"
                :to="{ name: 'auth-register-establishment' }"
                
              >
                <div class="d-flex align-items-center flex-column">
                  <img
                    src="@/assets/images/pages/Icon material-storefront.svg"
                    alt="Negocio"
                    class="svg-icon mb-2"
                  />
                  <span class="register-button-text">NEGOCIO</span>
                </div>
              </b-button>
              <!-- Botón Cliente -->
              <b-button
                variant="register-button"
                class="btn-lg mx-2 register-button"
                :to="{ name: 'auth-register-client' }"
                
              >
                <div class="d-flex align-items-center flex-column">
                  <img
                    src="@/assets/images/pages/Icon fa-solid-user-large.svg"
                    alt="Cliente"
                    class="svg-icon mb-2"
                  />
                  <span class="register-button-text">CLIENTE</span>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col md="6" lg="4" class="login-area d-flex flex-column align-items-center justify-content-center auth-bg px-2 p-lg-5 order-0 order-md-1">
        
          <div class="d-flex justify-content-center mb-3">
            <b-img :src="appLogoImage" alt="logo" width="250" class="logo" />
          </div>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            ¡Bienvenido! 
          </b-card-title>
          <b-card-text class="mb-2">
            Tu nueva forma de pagar y cobrar.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group
                label="Email o Teléfono celular"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Email"
                  vid="email"
                  rules="required|email_or_phone"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : valid ? true : null"
                    name="login-email"
                    tabindex="1"
                    autocomplete="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>¿Olvidaste tu contraseña?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors, valid }"
                  name="Password"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : valid ? true : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      tabindex="2"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-3"
                block
                :disabled="invalid"
              >
                Iniciar sesión
              </b-button>
            </b-form>
          </validation-observer>

          <div class="text-lef d-block d-lg-none mt-1 mb-8">
            <b-button
              class="text-muted"
              variant="link"
              :to="{ name: 'register-new-account' }"
              size="sm"
            >
              ¿No tienes cuenta? Regístrate gratis
            </b-button>
          </div>

          <!-- Divider and legal buttons -->
          <div class="text-center mt-4">
            <b-button
              class="text-muted"
              variant="link"
              :to="{ name: 'terms-of-use' }"
              size="sm"
            >
              Términos y condiciones
            </b-button>
            <b-button
              class="text-muted"
              variant="link"
              :to="{ name: 'privacy-policy' }"
              size="sm"
            >
              Aviso de Privacidad
            </b-button>
          </div>
      
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import { VBTooltip } from "bootstrap-vue"

import useJwt from "@/auth/jwt/useJwt"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import store from "@/store/index"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"
import { $themeConfig } from "@themeConfig"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    ValidationProvider,
    ValidationObserver,

    VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/BG2.png"),

      // validation rules
      required,
      email,
      deferredPrompt: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
  },
  created() {
    this.captureEvent()
  },
  /*mounted() {
    const device = this.getMobileOperatingSystem()
    if (device === 'Android') {
      this.$swal({
        title: '¿Te gustaría obtener la app?',
        text: 'Hemos detectado que estás desde un dispositivo móvil',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Descargar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          window.open('https://play.google.com/store/apps/details?id=com.mywalleat.app&hl=es_MX', '_blank')
        }
      })
    } else if(device === 'iOS')  { 
      this.$swal({
        title: '¿Te gustaría obtener la app?',
        html: 'Para instalar está aplicación, toca el botón de <strong>Compartir</strong> en tu en tu pantalla y selecciona <strong>Añadir a pantalla de inicio</strong>.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    }
  },*/
  methods: {
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone"
      }
      if (/android/i.test(userAgent)) {
        return "Android"
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS"
      }
      return "unknown"
    },
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Add analyticcs event
          this.$gtag.event("add_to_home_screen")
        }
        this.deferredPrompt = null
      })
    },
    ...mapActions("auth", ["signIn"]),
    ...mapActions("notifications", ["updateFirebaseToken"]),
    ...mapActions("users", ["attachReferral"]),
    ...mapMutations("landingPage", ["setCurrentUser"]),
    login() {
      let emailValidation = false
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmail)) {
        emailValidation = true
      }

      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.signIn({
            email: emailValidation === true ? this.userEmail : null,
            phone_number: emailValidation === false ? this.userEmail : null,
            password: this.password,
          })
            .then((response) => {
              const userData = response

              this.setCurrentUser(response)
              useJwt.setToken(response.token)
              useJwt.setRefreshToken(response.token)
              localStorage.setItem("userData", JSON.stringify(response))

              if (userData.scoped_roles && Array.isArray(userData.scoped_roles)) {
                userData.scoped_roles.forEach(role => {
                    if (role.role_resource_type === 'Establishment') {
                        localStorage.setItem("establishmentId", role.role_resource_id);
                    }
                });
              }

              const referralToken = localStorage.getItem("referralToken")
              if (referralToken) {
                const decodedToken = decodeURIComponent(referralToken)
                this.attachReferral({ encrypted_message: decodedToken }).then(
                  (res) => {
                    if (res.store_id) {
                      localStorage.removeItem("referralToken")
                      this.$router.push({
                        name: "e-commerce",
                        params: { store_id: res.store_id },
                      })
                    }
                  }
                )
              }

              if (
                this.$route.query &&
                this.$route.query.redirectFrom &&
                userData.role_name !== "establishment_admin"
              ) {
                this.$router
                  .replace(this.$route.query.redirectFrom)
                  .then(() => {
                    this.updateFirebaseToken()
                  })
              } else {
                const route = getHomeRouteForLoggedInUser(userData)

                this.$router.replace(route).then(() => {
                  this.updateFirebaseToken()
                })
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-center",
                props: {
                  title: "Error",
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: error.response.data.messages,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.add-button {
  position: absolute;
}

.logo {
  filter: invert(0.1);
  transition: filter 0.3s ease;
}

.dark-layout {
  .logo {
    filter: none;
  }
}

.object-fit-cover {
  object-fit: cover;
}

.login-area {
  @media screen and (max-width: 995px) {
    min-height: 100vh;
  }

}


.register-button {
  color: #ffffff; /* Texto blanco */
  border: 0;
  border-radius: 16px; /* Bordes redondeados */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, border-radius 0.3s, transform 0.3s; /* Agrega la transición para el hover */
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(40,48,69);
  background: linear-gradient(0deg, rgba(40,48,69,1) 0%, rgba(25,31,44,1) 100%);
  img {
    width: 54px; /* Tamaño de la imagen */
    height: 54px; /* Tamaño de la imagen */
  }

  @media screen and (max-width: 995px) {
    width: 120px;
    height: 100px;
    img {
      width: 24px; /* Tamaño de la imagen */
      height: 24px; /* Tamaño de la imagen */
    }

    .register-button-text {
      font-size: 1rem;
    }
    
  }
}

.register-button:hover {
  background-color: #7367f0; /* Color de fondo al pasar el ratón */
  color: #2d3e50; /* Texto oscuro al pasar el ratón */
  border-color: #7367f0; /* Cambiar borde al pasar el ratón */
  border-radius: 20px; /* Un poco más de redondeo al hacer hover */
  transform: scale(1.05); /* Agranda el botón un 5% al hacer hover */
}

.register-button {
  background-color: #2d3e50; /* Color oscuro de fondo */
  color: #ffffff; /* Texto blanco */
  border: 2px solid #2d3e50; /* Borde blanco */
}

.register-button:hover {
  background-color: #7367f0; /* Color de fondo al pasar el ratón */
  color: #ffffff; /* Texto oscuro al pasar el ratón */
  border-color: #7367f0; /* Cambiar borde al pasar el ratón */
}

.register-button:hover .register-button-text {
  color: #ffffff; /* Asegura que el texto sea oscuro al hacer hover */
}

.bg-img {
  background-image: url("../../assets/images/pages/BG2.png");
  background-size: cover;
  background-position: center;
}

.video-element {
  border-radius: 2rem;
}
</style>
