<template>
  <div>
    <b-input-group>
      <b-form-input
        id="sku"
        v-model="searchQuery"
        variant="outline-primary"
        :placeholder="placeholder"
        @input="onChange"
      />
      <b-input-group-append v-if="!searchQuery">
        <b-button class="mr-1" variant="primary" @click="handleShowScanner">
          <i class="fas fa-qrcode" />
        </b-button>
      </b-input-group-append>
      <b-input-group-append v-else>
        <b-button variant="outline-warning" @click="reset()"> Borrar </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-modal :id="scannerId" hide-footer>
      <StreamBarcodeReader @decode="onDecode" />
    </b-modal>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader"

export default {
  name: "SearchbarQrcode",
  components: {
    StreamBarcodeReader,
  },
  props: {
    placeholder: {
      type: String,
      default: "Busca por Nombre o Código de barras",
    },
    scannerId: {
      type: String,
      default: "qrcode-scanner",
    },
  },
  data() {
    return {
      searchQuery: "",
    }
  },
  methods: {
    onChange() {
      this.$emit("onChange", this.searchQuery)
    },
    onDecode(result) {
      this.searchQuery = result
      this.onChange()
      this.$bvModal.hide(this.scannerId)
    },
    async handleShowScanner() {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        })
        this.$bvModal.show(this.scannerId)
      } catch (error) {
        this.$bvToast.toast(
          "Necesitas dar permiso para acceder a la camara para poder escanear el codigo de barras",
          {
            title: "No se puede acceder a la camara",
            variant: "danger",
            solid: true,
          }
        )
      }
    },
    reset() {
      this.searchQuery = ""
      this.onChange()
    },
  },
}
</script>
