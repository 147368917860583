// Components
import ToastificationContent from "../components/toastification/ToastificationContent.vue"

// Utils
import { handleAPIErrors } from "../utils/fetch-utils"

export default {
  methods: {
    handleFetchError(e, errorMessage) {
      const errors = handleAPIErrors(e, errorMessage)

      if (errors && errors.length) {
        this.showErrorToast("Por favor, intenta de nuevo.", errors[0])
      }
    },

    showSuccessToast(title, text, icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "success",
          text,
        },
      })
    },

    warningToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "warning",
          text,
        },
      })
    },

    successToast(title, text, icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: "success",
        },
      })
    },

    warnToast({ title, text, icon = "AlertTriangleIcon" }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: "warning",
        },
      })
    },

    errorToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: "danger",
        },
      })
    },

    fetchErrorToast(e, errorMessage) {
      const errors = handleAPIErrors(e, errorMessage)

      if (errors && errors.length) {
        this.showErrorToast("Por favor, intenta de nuevo.", errors[0])
      }
    },

    showErrorToast(title, text, icon = "AlertTriangleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: "danger",
          text,
        },
      })
    },

    showErrorSwal({ title = "ERROR!", text = "Ha ocurrido un error" } = {}) {
      this.$swal({
        title,
        text,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    },

    successSwal({ title = "EXITO!", text = "Operación exitosa" } = {}) {
      this.$swal({
        title,
        text,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    },

    errorSwal({ title = "ERROR!", text = "Ha ocurrido un error" } = {}) {
      this.$swal({
        title,
        text,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    },
  },
}
