<template>
  <b-modal :id="id" :title="title" hide-footer scrollable>
    <store-product-grouped-simple-list
      :storeProducts="storeProducts"
      @onRemove="onRemoveProduct"
    />

    <div v-if="!storeProducts.length" class="text-center text-muted">
      No hay productos seleccionados
    </div>
  </b-modal>
</template>

<script>
import StoreProductGroupedSimpleList from "@/@core/components/store-products/store-product-grouped-simple-list.vue"

export default {
  components: {
    StoreProductGroupedSimpleList,
  },
  props: {
    id: {
      type: String,
      default: "cart-modal",
    },
    title: {
      type: String,
      default: "Resumen de productos seleccionados",
    },
    storeProducts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onRemoveProduct(product) {
      this.$emit("onRemove", product)
    },
  },
}
</script>
