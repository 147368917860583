<template>
  <b-container>
    <cart-simple-modal
      :storeProducts="selectedProducts"
      @onRemove="onRemoveProduct"
    />

    <overlay :show="loading">
      <main class="ecommerce-application">
        <b-card>
          <div class="d-flex justify-content-end px-1 mb-1">
            <b-button
              variant="primary"
              class="d-flex align-items-center"
              size="sm"
              @click="$bvModal.show('cart-modal')"
            >
              <feather-icon
                size="24"
                icon="ShoppingBagIcon"
                :badge="selectedProducts.length"
              />
              <span class="ml-50"> Productos seleccionados</span>
            </b-button>
          </div>

          <searchbar-qrcode @onChange="handleSearch" />

          <store-product-list>
            <li v-for="storeProduct in storeProducts" :key="storeProduct.id">
              <store-product-grouped
                :product="storeProduct"
                :onAddToCart="handleAddProduct"
                :validateInventory="false"
              />
            </li>
          </store-product-list>

          <pagination
            :handlePagination="handleStoreProductsPagination"
            :pagination="storeProductsPagination"
            :hideEntriesPerPage="true"
          />
        </b-card>

        <b-card>
          <b-button
            block
            class="d-flex align-items-center justify-content-center"
            variant="primary"
            @click="handleAddProducts"
          >
            <feather-icon size="24" icon="PlusIcon" />
            <span class="ml-50"> Agregar productos</span>
          </b-button>
        </b-card>
      </main>
    </overlay>
  </b-container>
</template>

<script>
import { mapActions } from "vuex"
import debounce from "lodash/debounce"

import StoreProductList from "@/@core/components/store-products/store-product-list.vue"
import StoreProductGrouped from "@core/components/store-products/store-product-grouped.vue"
import StoreProductGroupedSimpleList from "@/@core/components/store-products/store-product-grouped-simple-list.vue"
import CartSimpleModal from "@/@core/components/cart-simple-modal.vue"
import Pagination from "@/@core/components/Pagination.vue"
import SearchbarQrcode from "@/@core/components/searchbar-qrcode.vue"
import Overlay from "@core/components/Overlay.vue"

import messagesMixin from "@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  data() {
    const establishmentId = this.$route.params.id
    const supplierId = this.$route.params.supplier_id

    return {
      storeProducts: [],
      selectedProducts: [],
      establishmentId,
      supplierId,
      storeProductsPagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
      loading: false,
    }
  },
  mixins: [messagesMixin],
  components: {
    Overlay,
    StoreProductList,
    Pagination,
    SearchbarQrcode,
    CartSimpleModal,
    StoreProductGrouped,
    StoreProductGroupedSimpleList,
  },
  mounted() {
    this.getStoreProducts()
  },
  methods: {
    ...mapActions("users", ["updateSupplierStoreProducts"]),
    ...mapActions("storeProducts", ["fetchGroupedStoreProducts"]),

    onRemoveProduct(product) {
      const index = this.selectedProducts.findIndex((p) => p.id === product.id)

      if (index !== -1) {
        this.selectedProducts.splice(index, 1)
      }
    },

    handleStoreProductsPagination({ page }) {
      this.getStoreProducts({ page })
    },

    handleSearch: debounce(function (query) {
      if (!query) {
        this.getStoreProducts()
        return
      }

      if (/^\d*$/.test(query)) {
        const barcode = Number(query.substring(0, query.length - 1))

        this.getStoreProducts({
          by_sku: barcode,
        })
      } else {
        this.getStoreProducts({
          by_name: query,
        })
      }
    }, 500),

    getStoreProducts({ page, ...filters } = {}) {
      this.loading = true

      return this.fetchGroupedStoreProducts({
        ...filters,
        by_active_status: "true",
        meta: {
          pagination: { page },
        },
      })
        .then((res) => {
          this.storeProducts = res.data
          this.storeProductsPagination = res.meta.pagination
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorToast("Error", errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleAddProduct({ product, store_product }) {
      const index = this.selectedProducts.findIndex(
        (p) => p.id === store_product.id
      )

      if (index !== -1) {
        this.warnToast({ title: "Producto ya agregado" })
        return
      }

      this.selectedProducts.push({
        ...store_product,
        product_attributes: product,
        product_id: product.id,
      })
      this.successToast("Producto agregado")
    },

    handleAddProducts() {
      if (!this.selectedProducts.length) {
        this.warnToast({ title: "No hay productos seleccionados" })
        return
      }

      this.$swal({
        title: "¿Estás seguro?",
        text: "Estos productos se agregarán a la lista de productos del proveedor",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, agregar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary mr-1",
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const products = this.selectedProducts.map((sp) => {
            return {
              id: sp.id,
              store_id: sp.store_id,
            }
          })

          this.updateSupplierStoreProducts({
            params: {
              action: "add",
              supplier_id: this.supplierId,
              products_attributes: products,
            },
          })
            .then(() => {
              this.successToast("Productos agregados")
              this.$router.push({
                name: "establishment-proveedores",
                params: { id: this.$route.params.id },
              })
            })
            .catch((error) => {
              const errors = handleAPIErrors(error.response?.data)
              this.errorToast(errors[0])
            })
        }
      })
    },
  },
}
</script>

<styles lang="scss" scoped></styles>
