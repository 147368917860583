<template>
  <ul v-if="storeProducts.length" class="list-group list-group-flush">
    <li
      class="list-group item border p-50"
      v-for="product in storeProducts"
      :key="product.id"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="flex-1 d-flex align-items-center">
          <div class="mr-50">
            <b-img
              :src="product.product_attributes.logo"
              width="50"
              height="50"
              rounded
            />
          </div>
          <div>
            <div class="product-name mb-0 font-weight-bold">
              {{ product.product_attributes.name }}
            </div>
            <div class="font-weight-bolder">
              {{ product.product_attributes.variant }}
            </div>
            <div
              class="font-weight-bolder"
              v-if="
                product.store_product_variant_attributes &&
                product.store_product_variant_attributes.length &&
                product.store_product_variant_attributes[0]
                  .variant_option_attributes
              "
            >
              {{
                product.store_product_variant_attributes[0]
                  .variant_option_attributes.option_name
              }}
            </div>
          </div>
        </div>
        <div v-if="showDelete">
          <feather-icon
            class="cursor-pointer"
            icon="TrashIcon"
            size="16"
            @click="deleteProduct(product)"
          />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    storeProducts: {
      type: Array,
      default: () => [],
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteProduct(product) {
      this.$emit("onRemove", product)
    },
  },
  computed: {
    showDelete() {
      return !this.hideDelete
    },
  },
}
</script>
