<template>
  <div>
    <b-tabs >
      <b-tab v-if="userData.role_name === 'establishment_supplier'" active>
        <template #title>
          <feather-icon
            icon="ShoppingCartIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Proveeduria</span>
        </template>
        <supplier-products />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SupplierProducts from '@/views/suppliers/SupplierProducts.vue'

export default {
  components: {
    SupplierProducts,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';
</style>
