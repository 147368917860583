export const handleAPIErrors = (error, errorMessage = "Error inesperado") => {
  let errors = []

  if (error) {
    if (error.messages) {
      if (error.messages.base) {
        errors.push(...error.messages.base)
      } else if (typeof error.messages === "object") {
        for (const key in error.messages) {
          if (Array.isArray(error.messages[key])) {
            for (const err of error.messages[key]) {
              errors.push(err)
            }
          } else {
            errors.push(error.messages[key])
          }
        }
      } else {
        errors.push(error.messages)
      }
    } else if (error.errors) {
      for (const err of error.errors) {
        errors.push(err)
      }
    } else if (error.length) {
      for (const err of error) {
        errors.push(err)
      }
    }
  } else {
    errors.push(errorMessage)
  }

  if (errors.length === 0) {
    errors.push(errorMessage)
  }

  return errors
}
