<template>
  <div class="wrapper bg-img">
    <div class="">
      <h2 class="text-white font-weight-bolder mb-0 display-4">
        REGÍSTRATE GRATIS
      </h2>
      <p class="text-white mb-4 font-weight-bolder">
        SELECCIONA TU TIPO DE USUARIO
      </p>
      <div class="d-flex justify-content-center">
        <b-embed type="video" aspect="16by9" controls class="video-element">
          <source src="@/assets/compitienda_V1.mp4" type="video/mp4" />
        </b-embed>
      </div>
      <div class="buttons__container">
        <b-button
          variant="register-button"
          class="btn-lg register-button"
          :to="{ name: 'auth-register-establishment' }"
        >
          <img
            src="@/assets/images/pages/Icon material-storefront.svg"
            alt="Negocio"
            class="svg-icon mb-2"
          />
          <span class="register-button-text">NEGOCIO</span>
        </b-button>

        <b-button
          variant="register-button"
          class="btn-lg register-button client_button"
          :to="{ name: 'auth-register-client' }"
        >
          <img
            src="@/assets/images/pages/Icon fa-solid-user-large.svg"
            alt="Cliente"
            class="svg-icon mb-2"
          />
          <span class="register-button-text">CLIENTE</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      const sideImg = require("@/assets/images/pages/login-v2-dark.svg")
      return sideImg
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.register-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background-color: #2d3e50;
  color: #ffffff;
  border: 2px solid #2d3e50;
  border-radius: 16px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s,
    border-radius 0.3s, transform 0.3s;
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(40, 48, 69);
  background: linear-gradient(
    0deg,
    rgba(40, 48, 69, 1) 0%,
    rgba(25, 31, 44, 1) 100%
  );

  img {
    width: 54px;
    height: 54px;
  }
}

.buttons__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
}

.register-button:hover {
  background-color: #7367f0;
  color: #2d3e50;
  border-color: #7367f0;
  border-radius: 20px;
  transform: scale(1.05);
}

.register-button:hover .register-button-text {
  color: #ffffff;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 1rem;
  overflow: auto;
}

.client_button {
  order: 1;
}

.bg-img {
  background-image: url("../../assets/images/pages/BG2.png");
  background-size: cover;
  background-position: center;
}

.video-element {
  border-radius: 2rem;
}
</style>
