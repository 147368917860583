<template>
  <b-card no-body>
    <div class="m-2">
      <div>
        <h3>Productos que surtes...</h3>
      </div>

      <p>Buscar productos por nombre o código de barras</p>
      <searchbar-qrcode @onChange="handleSearchbarChange" />
    </div>

    <b-table
      :items="storeProducts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="text-left"
    >
      <!-- Products description -->
      <template #cell(name)="data">
        <b-card-text class="font-weight-bold mb-25 d-flex">
          <b-img
            width="40px"
            height="40px"
            :src="`${data.item.logo}`"
            rounded
            class="mr-1"
          />
          <div>
            {{ data.item.product_attributes.name }}
            <div>
              <b-badge
                v-for="category in data.item.categories_names"
                :key="`category-${category}`"
                variant="light-primary"
                class="font-weight-bold mb-25 d-flex text-center"
              >
                {{ category }}
              </b-badge>
            </div>
          </div>
        </b-card-text>
      </template>

      <template #cell(status)="data">
        <app-timeline>
          <app-timeline-item
            :variant="
              Number(data.item.needs_attention) == 0
                ? 'danger'
                : Number(data.item.needs_attention) == 1
                ? 'warning'
                : 'success'
            "
          >
          </app-timeline-item>
        </app-timeline>
      </template>

      <template #cell(details)="row">
        <b-button
          size="sm"
          :variant="'primary'"
          @click="row.toggleDetails"
          class="mr-2"
        >
          {{ row.detailsShowing ? "Ocultar" : "Ver" }} detalles
        </b-button>
      </template>
      <template #row-details="data">
        <b-card>
          <b-table
            :items="data.item.stores"
            responsive
            :fields="tableColumnsForStoreProducts"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="text-left"
          >
            <!-- Store name  -->
            <template #cell(name)="store">
              <b-card-text class="font-weight-bold mb-25 d-flex">
                <b-img
                  width="40px"
                  height="40px"
                  :src="`${store.item.logo}`"
                  rounded
                  class="mr-1"
                />
                <div>
                  <b-badge
                    variant="light-success"
                    class="font-weight-bold mb-25 d-flex text-center"
                  >
                    {{ store.item.name }}
                  </b-badge>
                  <div v-if="store.item.address_attributes">
                    <div
                      v-if="
                        store.item.address_attributes.street ||
                        store.item.address_attributes.ext_number
                      "
                    >
                      <b-badge
                        variant="light-warning"
                        class="font-weight-bold mb-25 d-flex text-center"
                      >
                        {{ store.item.address_attributes.street }}
                        {{ store.item.address_attributes.ext_number }},
                      </b-badge>
                    </div>
                    <div
                      v-if="
                        store.item.address_attributes.postal_code ||
                        store.item.address_attributes.city
                      "
                    >
                      <b-badge
                        variant="light-warning"
                        class="font-weight-bold mb-25 d-flex text-center"
                      >
                        CP:
                        {{ store.item.address_attributes.postal_code }},
                        {{ store.item.address_attributes.city }},
                      </b-badge>
                    </div>
                    <div
                      v-if="
                        store.item.address_attributes.state ||
                        store.item.address_attributes.country
                      "
                    >
                      <b-badge
                        variant="light-warning"
                        class="font-weight-bold mb-25 d-flex text-center"
                      >
                        {{ store.item.address_attributes.state }},
                        {{ store.item.address_attributes.country }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </template>

            <template #cell(contact)="store">
              <b-card-text
                v-if="store.item.cel_number"
                class="font-weight-bold mb-25 d-flex"
              >
                <b-badge
                  variant="light-primary"
                  class="font-weight-bold mb-25 d-flex text-center"
                >
                  {{ store.item }}
                  {{ store.item.cel_number }}
                </b-badge>
              </b-card-text>
            </template>

            <template #cell(inventory)="store">
              <app-timeline>
                <app-timeline-item
                  :variant="
                    Number(store.item.needs_attention) == 0
                      ? 'danger'
                      : Number(store.item.needs_attention) == 1
                      ? 'warning'
                      : 'success'
                  "
                >
                  <b-badge
                    v-if="store.item.needs_attention === 0"
                    :variant="'danger'"
                  >
                    Sin existencias
                  </b-badge>
                  <b-badge
                    v-else-if="store.item.needs_attention === 1"
                    :variant="'warning'"
                  >
                    Pocas existencias
                  </b-badge>
                  <b-badge v-else :variant="'success'">
                    Con existencias
                  </b-badge>
                </app-timeline-item>
              </app-timeline>
            </template>
          </b-table>
        </b-card>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <pagination
        :loading="loading"
        :pagination="pagination"
        :entriesPerPage.sync="entriesPerPage"
        :handlePagination="handlePagination"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import { debounce } from "lodash"
import { mapActions, mapGetters, mapMutations } from "vuex"

import Pagination from "@/@core/components/Pagination.vue"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import SearchbarQrcode from "@core/components/searchbar-qrcode.vue"

import { handleAPIErrors } from "@/utils/fetch-utils"
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    vSelect,

    Pagination,
    AppTimeline,
    AppTimelineItem,
    SearchbarQrcode,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      showHistoricalSales: false,
      searchQuery: "",
      isSku: false,
      isCameraScannerActive: false,
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      entriesPerPage: "10",
      storeProducts: [],

      tableColumns: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "status",
          label: "Estado",
        },
        {
          key: "details",
          label: "Detalles",
        },
      ],

      tableColumnsForStoreProducts: [
        {
          key: "name",
          label: "Sucursal",
        },
        {
          key: "contact",
          label: "Contacto",
        },
        {
          key: "units_needed",
          label: "Unidades requeridas",
        },
        {
          key: "inventory",
          label: "Inventario",
        },
      ],

      perPage: ["10", "20", "50", "100"],
    }
  },
  mixins: [messagesMixin],
  computed: {
    ...mapGetters("stores", ["pagination"]),
  },
  watch: {
    entriesPerPage() {
      this.getSupplierProducts()
    },
  },

  beforeMount() {
    this.getSupplierProducts()
  },

  methods: {
    ...mapActions("stores", ["fetchSupplierProducts"]),
    ...mapMutations("stores", [
      "setHistoricalInventoryDates",
      "setHistoricalInventoryValues",
    ]),

    toggleHistoricalSales(data) {
      this.initChartData(data)
      this.showHistoricalSales = !this.showHistoricalSales
    },

    initChartData(data) {
      const dataPoints = Object.entries(data).map(([date, value]) => ({
        date,
        value: Number(value),
      }))

      // Sort the dataPoints array in ascending order based on date
      dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date))

      // Extract the sorted dates and values
      const sortedDates = dataPoints.map((dataPoint) => dataPoint.date)
      const sortedValues = dataPoints.map((dataPoint) => dataPoint.value)

      this.setHistoricalInventoryDates(sortedDates)
      this.setHistoricalInventoryValues(sortedValues)
    },

    handleSearchbarChange: debounce(function (query) {
      if (!query) {
        this.isSku = false
        this.searchQuery = null

        this.getSupplierProducts()
        return
      }

      if (/^\d*$/.test(query)) {
        const barcode = Number(query.substring(0, query.length - 1))

        this.isSku = true
        this.searchQuery = barcode
      } else {
        this.searchQuery = query
      }

      this.getSupplierProducts()
    }, 250),

    getSupplierProducts({ page = 1, ...filters } = {}) {
      this.loading = true

      return this.fetchSupplierProducts({
        ...filters,
        by_sku: this.isSku ? Number(this.searchQuery) : null,
        by_name: this.isSku ? null : this.searchQuery,
        meta: {
          pagination: {
            page: page || 1,
            per_page: Number(this.entriesPerPage),
          },
        },
      })
        .then((response) => {
          this.storeProducts = response.data
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorToast(errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    handlePagination({ page, per_page }) {
      this.getSupplierProducts({
        page,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}
</style>
