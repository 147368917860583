<template>
  <b-container class="mt-2 ecommerce-application">
    <b-modal
      id="cart-modal"
      title="Resumen de productos seleccionados"
      class="modal-content"
      hide-footer
      scrollable
    >
      <store-product-grouped-simple-list
        :storeProducts="selectedProducts"
        @onRemove="onRemoveProduct"
      />

      <div v-if="!selectedProducts.length" class="text-center text-muted">
        No hay productos seleccionados
      </div>
    </b-modal>

    <Overlay :show="loading">
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        :finish-button-text="
          loading
            ? 'Procesando...'
            : $route.meta.isEstablishmentCustomer !== true
            ? 'Confirmar'
            : 'Siguiente'
        "
        next-button-text="Siguiente"
        back-button-text="Regresar"
        class="wizard-vertical mb-3"
        shape="square"
        step-size="sm"
        @on-complete="formSubmitted"
      >
        <tab-content
          title="Datos del proveedor"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <div class="mb-2">
              <h5 class="mb-1">Nombre del proveedor</h5>
              <b-form-group label-for="v-ammount">
                <validation-provider
                  #default="{ errors, valid }"
                  name="Nombre"
                  rules="required"
                >
                  <b-input-group class="">
                    <b-form-input
                      id="v-ammount"
                      v-model="user.name"
                      :state="errors.length > 0 ? false : valid ? true : null"
                      placeholder="José López"
                      size="lg"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div>
              <h5 class="mb-01">Correo electrónico</h5>
              <b-form-group label-for="v-loginFieldEmail">
                <validation-provider
                  #default="{ errors, valid }"
                  name="Correo electrónico"
                  rules="required|email"
                >
                  <b-input-group>
                    <b-form-input
                      id="v-loginFieldEmail"
                      v-model="user.email"
                      :state="errors.length > 0 ? false : valid ? true : null"
                      size="lg"
                      type="email"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div>
              <h5 class="mb-01">Teléfono celular</h5>
              <b-form-group label-for="v-loginFieldPhone">
                <validation-provider
                  #default="{ errors, valid }"
                  name="El celular es necesario para validar su cuenta"
                  rules="required|phone_number"
                >
                  <b-input-group>
                    <b-form-input
                      id="v-loginFieldPhone"
                      v-model="user.phone_number"
                      :state="errors.length > 0 ? false : valid ? true : null"
                      size="lg"
                      maxlength="10"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </validation-observer>
        </tab-content>

        <tab-content
          title="Productos que provee"
          :before-change="validateProducts"
        >
          <div class="mb-2">
            <div class="d-flex justify-content-end px-1 mb-1">
              <b-button variant="primary" @click="handleCartModal">
                <feather-icon
                  icon="ShoppingCartIcon"
                  size="16"
                  :badge="selectedProducts.length"
                />
              </b-button>
            </div>
            <searchbar-qrcode @onChange="handleSearch" />
          </div>

          <store-product-list>
            <li v-for="storeProduct in storeProducts" :key="storeProduct.id">
              <store-product-grouped
                :product="storeProduct"
                :onAddToCart="handleAddProduct"
                :validateInventory="false"
              />
            </li>
          </store-product-list>

          <pagination
            :handlePagination="handleStoreProductsPagination"
            :pagination="storeProductsPagination"
            :hideEntriesPerPage="true"
          />
        </tab-content>

        <tab-content title="Confirmar">
          <div>
            <div class="mb-1">
              <p class="mb-0 text-muted">Nombre:</p>
              <p class="display-5">
                {{ user.name }}
              </p>

              <p class="mt-1 text-muted">Correo electrónico:</p>
              <p class="display-5">
                {{ user.email }}
              </p>

              <p class="mt-1 text-muted">Celular:</p>
              <p class="display-5">
                {{ user.phone_number }}
              </p>
            </div>
            <div v-if="selectedProducts.length" class="mb-1">
              <store-product-grouped-simple-list
                :storeProducts="selectedProducts"
                @onRemove="onRemoveProduct"
              />
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </Overlay>
  </b-container>
</template>

<script>
import vSelect from "vue-select"
import debounce from "lodash/debounce"
import { mapActions } from "vuex"
import { FormWizard, TabContent } from "vue-form-wizard"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import Overlay from "@core/components/Overlay.vue"

import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"
import Pagination from "@/@core/components/Pagination.vue"
import SearchbarQrcode from "@/@core/components/searchbar-qrcode.vue"
import StoreProductList from "@/@core/components/store-products/store-product-list.vue"
import StoreProductGrouped from "@core/components/store-products/store-product-grouped.vue"
import StoreProductGroupedSimpleList from "@/@core/components/store-products/store-product-grouped-simple-list.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Overlay,

    StoreProductGroupedSimpleList,
    StoreProductList,
    StoreProductGrouped,
    SearchbarQrcode,
    Pagination,

    FormWizard,
    TabContent,

    vSelect,
  },
  mixins: [messagesMixin],
  data() {
    return {
      user: {
        name: null,
        phone_number: null,
        role_resource_id: null,
        product_attributes: {
          product_id: null,
          store_id: null,
        },
      },
      loading: false,
      establishmentId: this.$route.params.id,
      storeProducts: [],
      storeProductsPagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
      selectedProducts: [],
    }
  },
  async mounted() {
    await this.getStoreProducts()
  },
  methods: {
    ...mapActions("users", ["registerEstablishmentSupplier"]),
    ...mapActions("stores", ["fetchStores"]),
    ...mapActions("establishments", ["fetchEstablishments"]),
    ...mapActions("storeProducts", ["fetchGroupedStoreProducts"]),

    onRemoveProduct(product) {
      const index = this.selectedProducts.findIndex((p) => p.id === product.id)

      if (index !== -1) {
        this.selectedProducts.splice(index, 1)
      }
    },

    handleAddProduct({ product, store_product }) {
      const index = this.selectedProducts.findIndex(
        (p) => p.id === store_product.id
      )

      if (index !== -1) {
        this.warnToast({ title: "Producto ya agregado" })
        return
      }

      this.selectedProducts.push({
        ...store_product,
        product_attributes: product,
        product_id: product.id,
      })

      this.successToast("Producto agregado")
    },

    handleSearch: debounce(function (query) {
      if (!query) {
        this.getStoreProducts()
        return
      }

      if (/^\d*$/.test(query)) {
        const barcode = Number(query.substring(0, query.length - 1))

        this.getStoreProducts({
          by_sku: barcode,
        })
      } else {
        this.getStoreProducts({
          by_name: query,
        })
      }
    }, 500),
    handleStoreProductsPagination({ page }) {
      this.getStoreProducts({ page })
    },

    handleCartModal() {
      this.$bvModal.show("cart-modal")
    },

    async getStoreProducts({ page, ...filters } = {}) {
      try {
        this.loading = true

        const res = await this.fetchGroupedStoreProducts({
          ...filters,
          by_active_status: "true",
          meta: {
            pagination: { page },
          },
        })

        this.storeProducts = res.data
        this.storeProductsPagination = res.meta.pagination
      } catch (error) {
        const errors = handleAPIErrors(error.response?.data)
        this.errorToast("Error", errors[0])
      } finally {
        this.loading = false
      }
    },

    formSubmitted() {
      this.loading = true
      this.user.role_resource_id = this.establishmentId
      this.user.product_attributes = this.selectedProducts.map((product) => ({
        id: product.id,
        store_id: product.store_id,
      }))
      this.user.phone_number = `${52}${this.user.phone_number}`

      this.registerEstablishmentSupplier(this.user)
        .then((res) => {
          this.successSwal({
            title: "Usuario registrado exitosamente!",
            text: "Crea un pedido automatico para este proveedor",
          })
          this.$router.push({
            name: "add-store-automatic-restocking",
            params: { id: this.establishmentId },
            query: { supplier: res.name },
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorSwal({ text: errors[0] })
        })
        .finally(() => {
          this.loading = false
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateProducts() {
      return new Promise((resolve, reject) => {
        if (this.selectedProducts.length) {
          resolve(true)
        } else {
          this.errorToast("Error", "Debes seleccionar al menos un producto")
          reject()
        }
      })
    },
  },
}
</script>
<style lang="scss">
.wizard-icon-container {
  border-radius: 6px !important;
  background: red;
}
.wizard-nav.wizard-nav-pills {
  display: none;
}
</style>
