<template>
  <b-table
    ref="refSupplierListTable"
    :items="users"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
    class="position-relative providers-table"
  >
    <!-- Column: Establishment Supplier -->
    <template #cell(name)="data">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="32"
            :src="data.item.logo"
            :text="avatarText(data.item.name)"
            :variant="`light-primary`"
          />
        </template>
        <b-link class="font-weight-bold d-block text-nowrap">
          {{ data.item.name }}
        </b-link>
        <small class="text-muted">{{ data.item.email }}</small>
      </b-media>
    </template>

    <!-- Column: Contact -->
    <template #cell(cel_number)="data">
      <template>
        <div>
          <b-badge pill :variant="'light-success'">
            {{ data.item.phone_number.slice(2) }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Details button -->
    <template #cell(products)="row">
      <b-button
        size="sm"
        :variant="'primary'"
        @click="row.toggleDetails"
        class="mr-2"
      >
        {{ row.detailsShowing ? "Ocultar" : "Ver" }} productos
      </b-button>
    </template>

    <!-- Actions -->
    <template #cell(actions)="data">
      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item
          :to="{
            name: 'establishment-proveedores-productos',
            params: { id: $route.params.id, supplier_id: data.item.id },
          }"
        >
          <feather-icon icon="PlusIcon" />
          <span class="align-middle ml-50">Agregar products</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item @click="handleDelete(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Eliminar</span>
        </b-dropdown-item> -->
      </b-dropdown>
    </template>

    <template #row-details="data">
      <b-card>
        <b-row class="mb-2">
          <b-table
            :items="data.item.scoped_roles"
            responsive
            :fields="tableColumnsForStoreProducts"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="text-left"
          >
            <!-- Product  -->
            <template #cell(product_name)="store_product">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="store_product.item.role_resource_logo"
                    :text="avatarText(store_product.item.role_resource_name)"
                    :variant="`light-primary`"
                  />
                </template>
                <b-link class="font-weight-bold d-block text-nowrap">
                  {{ store_product.item.role_resource_name }}
                </b-link>
              </b-media>
            </template>

            <!-- Store name  -->
            <template #cell(store_name)="store_product">
              <template>
                <div>
                  <b-badge pill :variant="'light-warning'">
                    {{ store_product.item.role_store_name }}
                  </b-badge>
                </div>
              </template>
            </template>

            <template #cell(actions)="store_product">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="
                    handleRemoveSupplierProduct(store_product.item, data.item)
                  "
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Borrar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-row>
      </b-card>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex"

import { avatarText } from "@core/utils/filter"

export default {
  data() {
    return {
      tableColumns: [
        {
          key: "name",
          label: "Proveedor",
        },
        {
          key: "cel_number",
          label: "Contacto",
        },
        {
          key: "products",
          label: "Productos asignados",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],

      tableColumnsForStoreProducts: [
        {
          key: "product_name",
          label: "Nombre de producto",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapGetters("users", ["users", "pagination"]),
  },
  props: {
    // handleDelete: {
    //   type: Function,
    //   default: () => {},
    // },
    handleRemoveSupplierProduct: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
